































import { defineComponent } from '@vue/composition-api'
import Banner from './components/banner.vue'
import { projects } from '@/views/smart-sports/data'

export default defineComponent({
  name: 'SmartSportsDetailsDesk',
  components: { Banner },
  setup(props, context) {
    const { type, id } = context.root.$route.params || {}
    const detail =
      projects[type].find((i: { id: string }) => String(i.id) === id)?.detail ||
      []
    console.log(detail, 111)
    return {
      detail,
      type
    }
  }
})
